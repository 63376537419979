
















































































































































import Vue from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mdiPencil, mdiDelete, mdiChevronLeft, mdiChevronRight, mdiChevronDoubleDown } from '@mdi/js';
import { GET_CURRENT_USER, CurrentUser } from '@/store/users';
import {
  FETCH_PROJECTS,
  GET_PROJECTS,
  Project,
  SET_CURRENT_PROJECT,
  FETCH_PROJECT_PICTURE,
  GET_PROJECTS_PICTURE,
} from '@/store/project';
import {
  FETCH_MULTIPLE_ACDB,
  GET_ACDB,
  AccessControlRule,
  GetAccessControl,
} from '@/store/acdb';
import ProjectDeleteDialog from '@/components/ProjectDeleteDialog.vue';

interface VueComponent {
  // data
  loading: boolean;
  // methods
  fetchProjects: () => void;
  fetchMultipleAcdb: (rules: AccessControlRule[]) => void;
  loadProjects: () => void;
  canUpdateProject: (project: Project) => boolean;
  canDeleteProject: (project: Project) => boolean;
  // computed
  projects: Project[];
  currentUser: CurrentUser;
  getAcdb: GetAccessControl;
}

export default Vue.extend({
  name: "ProjectManagementCard",
  components: {
    ProjectDeleteDialog,
  },
  data() {
    return {
      loading: true,
      editIcon: mdiPencil,
      deleteIcon: mdiDelete,
      chevronLeftIcon: mdiChevronLeft,
      chevronRightIcon: mdiChevronRight,
      mdiChevronDoubleDown,

      itemsPerPage: 8,
      itemsPerPageArray: [4, 8, 12],
      page: 1,

      dialogDelete: false,
      selectedProject: {},
    };
  },
  methods: {
    ...mapActions({
      fetchProjects: FETCH_PROJECTS,
      fetchProjectPicture: FETCH_PROJECT_PICTURE,
      fetchMultipleAcdb: FETCH_MULTIPLE_ACDB,
    }),

    ...mapMutations({
      setCurrentProject: SET_CURRENT_PROJECT,
    }),

    async loadProjects() {
      await this.fetchProjects();
    },

    // events
    async onOpenProject(project: Project) {
      await this.setCurrentProject(project);
      this.$router.push({ name: 'Project' });
    },

    getProjectPicture(item: any) {
      if(this.projectsPicture[item.id])
      return this.projectsPicture[item.id].src
    },

    // pagination events
    nextPage(): void {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage(): void {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number: number): void {
      this.itemsPerPage = number;
    },

    // ACDB actions
    canUpdateProject(project: Project) {
      const fn: GetAccessControl = this.getAcdb;
      let updateProject = fn('update', 'project').hasAccess;
      let updateOwnProject = fn('update', 'ownProject').hasAccess;
      return (
        updateProject ||
        (updateOwnProject && project.owner === this.currentUser.id)
      );
    },
    canDeleteProject(project: Project) {
      const fn: GetAccessControl = this.getAcdb;
      let deleteProject = fn('update', 'project').hasAccess;
      return deleteProject;
    },

    openDeleteProjectDialog(selectedProject: Project) {
      Object.assign(this.selectedProject, {}, selectedProject);
      this.$forceUpdate();
      this.dialogDelete = true;
    },

    closeDeleteProjectDialog() {
      this.dialogDelete = false;
      this.selectedProject = ({} as unknown) as Project;
    },

    async closeDeleteProjectDialogOnConfirm() {
      (this as any).closeDeleteProjectDialog();
      await (this as any).loadProjects();
    },
  },
  computed: {
    ...mapGetters({
      projects: GET_PROJECTS,
      getAcdb: GET_ACDB,
      currentUser: GET_CURRENT_USER,
      projectsPicture: GET_PROJECTS_PICTURE,
    }),
    numberOfPages(): number {
      return Math.ceil(this.projects.length / this.itemsPerPage);
    },
  },
  async created() {
    await this.fetchMultipleAcdb([
      {
        accessType: 'update',
        resourceName: 'project',
      },
      {
        accessType: 'delete',
        resourceName: 'project',
      },
    ]);
    this.loading = true;
    await this.loadProjects();
    await Promise.all(
      this.projects.map((project: any) => this.fetchProjectPicture(project.id)),
    );
    this.loading = false;
  },
});

