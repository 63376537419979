
































import Vue from 'vue';
import { mapActions } from 'vuex';
import { DELETE_PROJECT } from '@/store/project';

export default Vue.extend({
  name:"ProjectDeleteDialog",
  props: [
    'selectedProject',
    'closeDeleteProjectDialog',
    'closeDeleteProjectDialogOnConfirm',
  ],
  data: () => {
    return {
      dialogDelete: false,
    };
  },
  methods: {
    ...mapActions({
      deleteProject: DELETE_PROJECT,
    }),
    closeDelete() {
      this.closeDeleteProjectDialog();
    },
    async deleteElementConfirm() {
      await this.deleteProject(this.selectedProject.id);
      await this.closeDeleteProjectDialogOnConfirm();
    },
  },
});

